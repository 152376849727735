import React from "react";
import { Footer, Header } from "../components/Layouts";
import { Home, ProductsContainer } from "../components/Products";

const Products = () => {
  return (
    <div>
      <Header />
      <Home />
      <Footer />
    </div>
  );
};

export default Products;
