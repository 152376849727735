import React from "react";
import { Footer, Header } from "../components/Layouts";
import { Home } from "../components/Services";

const Services = () => {
  return (
    <div>
      <Header />
      <Home />
      <Footer />
    </div>
  );
};

export default Services;
