import React from "react";

const Form = () => {
  return (
    <div className="">
      <form className="w-full">
        <div className="my-4">
          <input
            type={"text"}
            className="form-control border border-gray-200 rounded-lg shadow-md bg-white px-5 py-4 primary-font w-full placeholder:text-black"
            placeholder={"Name"}
            required={true}
          />
        </div>
        <div className="my-4">
          <input
            type={"email"}
            className="form-control border border-gray-200 rounded-lg shadow-md bg-white px-5 py-4 primary-font w-full placeholder:text-black"
            placeholder={"Email"}
            required={true}
          />
        </div>
        {/* Message*/}
        <div className="w-full">
          <textarea
            placeholder="Message"
            rows={5}
            className="form-control border border-gray-200 rounded-lg shadow-md bg-white px-5 py-4 primary-font w-full placeholder:text-black"
          ></textarea>
        </div>
        <div className="flex mt-8 mb-2">
          <button className="text-white text-center bg-green-600 px-14 py-2 font-medium rounded-md ">
            Send Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
