import React, { useState } from "react";
import "./gallery.css";
import diffImg1 from "../../assets/images/diffimgone.png";
import diffImg2 from "../../assets/images/diffimgtwo.png";
import diffImg3 from "../../assets/images/diffimgthree.png";

const Home = () => {
  const [model, setModel] = useState(false);
  const [tempImg, setTempImg] = useState("");
  const data = [
    {
      id: 1,
      image: diffImg1,
      title: "Market",
    },
    {
      id: 2,
      image: diffImg2,
      title: "Finance",
    },
    {
      id: 3,
      image: diffImg3,
      title: "Advisory",
    },
    {
      id: 4,
      image: diffImg1,
      title: "Market",
    },
    {
      id: 5,
      image: diffImg2,
      title: "Finance",
    },
    {
      id: 6,
      image: diffImg3,
      title: "Advisory",
    },
  ];
  const getImg = (imgSrc) => {
    setTempImg(imgSrc);
    setModel(true);
  };
  return (
    <div className="py-24">
      <div className={model ? "model open" : "model"}>
        <img src={tempImg} />
      </div>
      {/* First Section */}
      <div className="md:px-28 px-12 py-12 ">
        <div>
          <h1 className="font-light text-5xl text-green-600">
            Growing with passion
          </h1>
          <p className="mt-4 text-xl">
            A visual journey through our farm's seasons
          </p>
        </div>
      </div>
      {/* Photo Gallery */}
      <div className="gallery md:px-28 px-12 py-12">
        {data.map((item, index) => (
          <div
            className="pics"
            key={index}
            //    onClick={() => getImg(item.image)}
          >
            <img src={item.image} style={{ width: "100%" }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
