import React from "react";

const MainContent = () => {
  const data = [
    {
      title: "infocert",
      description:
        "At Infocert, we help translate business strategy into ICT strategy and design the ICT solutions that can adequately support current and future business evolutions. We work with you to turn your enterprise architecture into the bridge between your business imperatives and the underlying ICT infrastructure. ",
    },
    {
      title: "koboweb",
      description:
        "KoboWeb aims to transform the agricultural landscape and economy by stimulating an ecosystem that enables all-inclusive economic growth and prosperity for the nation through smallholder farming communities using a three-prong approach: access to knowledge, finance, and market.",
    },
    {
      title: "turgl technologies",
      description:
        "Turgl- Enhancing healthcare delivery. Empowering hospitals to run better. #healthtech",
    },
    {
      title: "supercad",
      description:
        "Supercad is a network of IT specialists who believe in delivering real-time measurable results. Our services are based on the core client needs, and we implement our expertise to deliver the required results. ",
    },
  ];
  return (
    <div className="w-full lg:px-32 md:px-32 px-10 py-8">
      {data.map((item, index) => (
        <div
          key={index}
          className={`${
            index === data.length - 1 ? "" : "border-b border-b-black"
          } my-4 py-4`}
        >
          <h1 className="text-2xl font-bold pb-4 text-pink-600 uppercase">
            {item.title}
          </h1>
          <p className="font-normal text-sm text-justify">{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default MainContent;
