import { Header } from "../Layouts";

const Hero = () => {
  return (
    <div className="hero-home">
      <div className="hero-content md:px-28 px-10 md:py-2 pt-28">
        {/* First Row */}
        <div className="">
          {/* Header */}
          <h1 className=" text-white md:text-5xl text-3xl md:leading-[60px] font-medium">
            The Heartbeat <br />
            of the Farmer
          </h1>
          <p className="text-md text-justify text-white font-thin my-4">
            Revitalizing agriculture and nurturing rural <br /> communities
            through sustainable farming <br /> practices and farmer-centered
            initiatives.
          </p>
          <button className="bg-green-600 text-white rounded-full py-2 px-5 md:mb-24 mb-12">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
