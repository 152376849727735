const Home = () => {
  return (
    <div className="about-home">
      <div className="about-home-content md:px-28 px-10">
        {/* Heading */}
        <h1 className="md:text-5xl text-xl font-normal text-green-600">
          Transforming <br />
          agriculture <br /> with technology
        </h1>
        <div className="pt-4 mb-10">
          <h1 className="text-black font-normal text-lg">
            Empowering farmers for a sustainable future.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Home;
