import React from "react";
import Image from "../../assets/images/aboutcompany.png";
const CompanyDesc = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 md:px-24 px-12 my-14">
        {/* Description */}
        <div className="my-2 py-5 md:pr-20 w-[full]">
          <p className="font-light text-black text-md text-justify mt-8">
            KoboWeb is a cutting-edge agro technology platform aimed at
            providing farmers with markets, finances, and expert advice to help
            them make sound decisions regarding their agricultural activities.
            Our platform offers unparalleled access to resources and guidance
            that empower farmers to achieve success in their endeavors.
          </p>
        </div>
        {/* Image */}
        <div className="my-2 py-5 relative w-full md:pl-10 pl-0">
          {/* Icon */}
          <div className="flex justify-center"></div>
          <img src={Image} alt="ABout Us" className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default CompanyDesc;
