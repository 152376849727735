import React, { useState } from "react";
import { Link } from "react-router-dom";

import DropDownListItem from "./DropDownListItem";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../../assets/images/logo.png";
import whiteLogo from "../../assets/images/whiteLogo.png";
const Header = ({ home }) => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);
  const logoImg = home ? whiteLogo : logo;
  return (
    <nav
      className={`nav-area w-screen z-10 flex justify-between items-center font-light md:px-24 px-5 md:pt-0 pt-5 absolute ${
        home ? "text-white" : "text-black"
      }`}
    >
      <div className="logo_container">
        <Link to={"/"}>
          <img src={logoImg} alt="" className="md:h-[40px] h-[25px] mt-4" />
        </Link>
      </div>
      <ul className="hidden md:flex">
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to={"/"}>Home</Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to={"/about"}>About</Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to={"/products"}>Products</Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to={"/services"}>Services</Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to={"/gallery"}>Gallery</Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`flex font-light hover:text-green-600`}
        >
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div className="md:hidden" onClick={handleClick}>
        {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
      </div>

      <ul
        className={!nav ? "hidden" : "absolute top-20 left-0 bg-black w-full"}
      >
        <li className="border-b border-white w-full text-white">
          <Link onClick={handleClose} to="/" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="border-b border-white w-full text-white">
          <Link onClick={handleClose} to="/about" smooth={true} duration={500}>
            About
          </Link>
        </li>

        <li className="border-b border-white w-full text-white">
          <Link
            onClick={handleClose}
            to="/products"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Products
          </Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`border-b border-white w-full text-white`}
        >
          <Link
            onClick={handleClose}
            to="/services"
            smooth={true}
            duration={500}
          >
            Services
          </Link>
        </li>
        <li
          style={{ cursor: "pointer" }}
          className={`border-b border-white w-full text-white`}
        >
          <Link
            onClick={handleClose}
            to="/gallery"
            smooth={true}
            duration={500}
          >
            Gallery
          </Link>
        </li>

        <li className=" w-full text-white">
          <Link
            onClick={handleClose}
            to="/contact"
            smooth={true}
            offset={-50}
            duration={500}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
