import { Home } from "../components/Contact";
import { Footer, Header } from "../components/Layouts";

function Contact() {
  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <Header />
        <Home />
        <Footer />
      </div>
    </>
  );
}

export default Contact;
