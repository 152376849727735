const Home = () => {
  return (
    <div className="patners-home">
      <div className="patners-home-content md:px-28 px-10">
        {/* Heading */}
        <div className="text-white mt-8 pt-4">
          <h1 className="uppercase font-light md:text-5xl text-2xl">
            our <br /> Partners
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Home;
