import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Image1 from "../../assets/images/aboutimgone.png";
import Image2 from "../../assets/images/aboutimgtwo.png";
import Image3 from "../../assets/images/aboutimgthree.png";
import Image4 from "../../assets/images/aboutimgfour.png";

const SocialRes = () => {
  const data = [
    { img: Image1 },
    { img: Image2 },
    { img: Image3 },
    { img: Image4 },
  ];
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full py-10 md:px-28 px-10">
        {/* Description */}
        <div className="my-2 py-5 md:pr-10">
          <h1
            style={{}}
            className=" font-medium md:text-4xl text-2xl text-green-600 "
          >
            Join our Team
          </h1>
        </div>
        {/* Image */}
        <div className="">
          <p className="text-gray-600 font-normal text-justify mb-4">
            At our company, we hold the belief that producing exceptional
            products requires exceptional people. This is why we seek to recruit
            not only individuals with the necessary professional skills, but
            also those who embody our company's values. See open positions
          </p>
          <Link className="font-bold underline">See open positions</Link>
        </div>
      </div>
      {/* Quote */}
      <div className="w-full flex bg-gray-400 py-10 md:px-28 px-5">
        <div className="md:ml-4">
          <FaQuoteLeft size={50} className="text-gray-200" />
        </div>
        <div>
          <h1 className=" italic md:text-4xl text-gray-600 md:ml-20 ml-2">
            Our work does make sense only <br /> if it is faithful witness of
            his time
          </h1>
          <p className="text-light text-md text-right text-gray-600">
            John Olorunfemi - Director, Koboweb
          </p>
        </div>
      </div>
      {/* Proudly Built for Nigeria */}
      <div className="md:px-24 px-12 my-14">
        <div>
          <h1 className="text-green-600 font-medium md:text-4xl text-2xl pb-5">
            Proudly Built for Nigeria
          </h1>
          <div className="">
            <p className="text-gray-600 font-normal text-justify md:text-lg text-sm mb-4 md:w-[400px]">
              KoboWeb was established in Abuja, the capital city, as a spin-off
              of the agricultural platform SCL (Sa'I'Anwara'I Ju'Mai Consultaire
              Limited). It has since evolved into an Nigerian success story,
              founded on a groundbreaking concept related to the agricultural
              industry and developed by a diverse team of intelligent and
              compassionate individuals from around the world.
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-wrap justify-between items-center ">
            {data.map((item, index) => (
              <div className="md:h-[350px] md:w-[500px] my-5" key={index}>
                <img
                  src={item.img}
                  className="h-[90%] w-full rounded-lg"
                  alt="User"
                />
                <div>
                  <h1 className="font-bold">{item.name}</h1>
                  <p className="font-light text-sm text-gray-500">
                    {item.role}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialRes;
