import React from "react";
import Form from "./Form";
import map from "../../assets/images/map.png";

const Home = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 md:px-24 px-8 py-20">
      <div>
        <h1 className="font-light md:text-5xl text-2xl text-green-600 my-2">
          Get in touch with our team
        </h1>
        <div className="mt-8">
          <h2 className="text-sm my-2 font-bold">
            KoboWeb Greentech Group Ltd
          </h2>
          <p className="text-sm">
            No. 8 Zambezi Crescent, Off Aguiyi Ironsi Street, Maitama, Abuja
          </p>
        </div>
        {/* Map */}
        <div className="my-5">
          <img src={map} className="h-[300px] w-[420px]" />
        </div>
      </div>
      {/* Form */}
      <div className="my-2 py-5 relative w-full md:pl-10 pl-0">
        <Form />
      </div>
    </div>
  );
};

export default Home;
