import {
  About,
  Contact,
  FAQ,
  Hero,
  LeadingEdge,
  MissionVision,
  TheDifference,
} from "../components/Home";
import { Footer, Header } from "../components/Layouts";

function Home() {
  return (
    <>
      <div>
        <Header home={true} />
        <Hero />
        <About />
        <MissionVision />
        <TheDifference />
        <LeadingEdge />
        {/* <FAQ /> */}
        {/* <Contact /> */}
        <Footer />
      </div>
    </>
  );
}

export default Home;
