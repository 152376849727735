import React from "react";
const MissionVision = () => {
  return (
    <div className="w-full md:px-28 px-10 my-12">
      <div
        className="grid grid-cols-1 md:grid-cols-2 items-center py-10 md:px-10 px-4 "
        style={{ backgroundColor: "rgba(0, 161, 75, 0.1)" }}
      >
        {/* First Item */}
        <div className="md:pr-12 pr-0  md:border-r md:border-r-gray-500 ">
          <h1 style={{}} className=" font-medium text-xl text-black mt-2">
            Mission
          </h1>
          <p className="font-normalmd:text-md text-sm text-gray-700 text-justify mt-8">
            To improve small-scale farming by helping farmers get access
            to knowledge, finance, and market.
          </p>
        </div>
        {/* Second Item */}
        <div className="md:pl-12">
          <h1 style={{}} className=" font-medium text-xl text-black mt-2">
            Vision
          </h1>
          <p className="font-normal md:text-md text-sm text-gray-700 text-justify mt-8">
            To transform the agricultural landscape and economy by stimulating
            an ecosystem that provides access to knowledge, finance, and market
            for smallholder farmers.
          </p>
        </div>
      </div>
    </div>
  );
};
// AiOutlineRadarChart
export default MissionVision;
