import React from "react";
import infoCert from "../../assets/images/infocert.png";
const PatnersInfo = () => {
  return (
    <div
      style={{ background: "#293586" }}
      className="w-full text-white lg:px-32 md:px-32 py-8"
    >
      <h1 className="md:text-3xl text-5xl uppercase font-light pb-4 text-center">
        Partners
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-4 items-center justify-center">
        {/* First Item */}
        <div className="mx-auto my-5  md:border-r border-r-white ">
          <div style={{ width: "180px" }} className="bg-white p-2 rounded-sm">
            <img src={infoCert} alt="Info Cert" />
          </div>
        </div>
        {/* Second Item */}
        <div className="mx-auto my-5  md:px-14 ">
          <h1 className="text-4xl font-light">
            Kobo<span className="font-bold">web</span>
          </h1>
        </div>
        {/* Third Item */}
        <div className="md:px-12 mx-auto my-5 md:border-l border-l-white md:border-r border-r-white ">
          <h1 className="font-black text-5xl">Turgl</h1>
          <h1 className="font-light text-2xl">Technologies</h1>
        </div>
        {/* Fourth Item */}
        <div className="md:px-14 mx-auto my-5">
          <h1 className="text-4xl font-light">
            Super<span className="font-bold">CAD</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default PatnersInfo;
