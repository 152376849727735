import { FaFacebook, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import WhiteBG from "../../assets/images/whiteLogo.png";
const Footer = () => {
  const genericLink = "flex font-light -my-5 hover:text-green-600 md:ml-10";
  return (
    <div className="w-full mygray text-white py-10 md:px-20 px-5 justify-between bg-black">
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-3 border-b border-white md:pt-16 md:pb-16 pb-10">
        {/* Firtst Column  */}
        <div>
          <img src={WhiteBG} alt="ABout Us" />
          <p className="md:text-md text-sm text-justify">
            KoboWeb is a cutting-edge agro technology platform aimed at
            providing farmers with markets, finances, and expert advice to help
            them make sound decisions regarding their agricultural activities.
          </p>
          <div className="flex  space-x-4  justify-start md:mb-0 mb-5 sm:w-[300px] pt-4 text-2xl">
            <a
            // href={"https://www.linkedin.com/company/remotewebnet/"}
            >
              <FaLinkedinIn />
            </a>
            <FaTwitter />
            <FaFacebook />
          </div>
        </div>
        {/* Second Column */}
        <div>
          <h1 className="font-medium uppercase md:text-xl text-sm mb-5 md:text-center">
            links
          </h1>
          <div className="flex md:justify-center md:items-center">
            <ul className="">
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/"}>Home</Link>
              </li>
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/about"}>About</Link>
              </li>
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/"}>Services</Link>
              </li>
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/"}>Products</Link>
              </li>
              <li style={{ cursor: "pointer" }} className={genericLink}>
                <Link to={"/"}>Gallery</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="md:mt-0 mt-16">
          <h1 className="font-bold uppercase text-xl mb-5 ">contact</h1>
          <p>
            4th Floor Main Building , Veterinary Council of Nigeria, No. 8
            Zambezi Crescent, Maitama, Abuja, FCT, Nigeria
          </p>
        </div>
      </div>
      <p className="py-4 font-light text-center">
        KoboWebGreenTech Ltd @ 2023 All rights reserved
      </p>
    </div>
  );
};

export default Footer;
