import React from "react";
import Image from "../../assets/images/leading-edge.png";
import joinUsDevice from "../../assets/images/joinUsDevice.png";
import { BsArrowDown } from "react-icons/bs";

const Home = () => {
  const data = [
    "Download the app from either the Play Store for Android users or the App Store for IOS users.",
    "Once you have downloaded the app, open it and you will be prompted to create an account or sign in if you already have one.",
    "If you need to create an account, click on the 'Create Account' button and provide the necessary information, such as your name, email address, and a strong password.",
    "After you have created an account, you will be directed to the login page. Enter your email address and password, and then click on the 'Login' button.",
    "Once you have successfully logged in, you will be taken to the app's main dashboard, where you can access all the resources and guidance that our platform offers.",
  ];

  return (
    <div>
      <div className="md:px-24 px-12 mb-14 pt-24">
        {/* First Section */}
        <div className="w-full md:flex justify-center">
          {/* Description */}
          <div className="my-2 py-5 w-full">
            <h1
              style={{}}
              className="font-bold md:text-5xl text-3xl text-green-600"
            >
              Kobokolet
            </h1>
            <p className="text-lg font-medium">The Farmer's Pulse</p>
            <div
              className="w-[220px] px-4 py-5 rounded-md mt-10"
              style={{ backgroundColor: "rgba(0, 161, 75, 0.1)" }}
            >
              <p className="leading font-semibold text-md">
                Available on Playstore
              </p>
              <p className="text-mono text-md">Playstore & App Store</p>
            </div>
            <div className="md:mt-20 mt-10">
              <BsArrowDown size={50} className="text-green-600" />
            </div>
          </div>
          {/* Image */}
          <div className="my-2 py-5 relative w-full">
            {/* Icon */}
            <div className="flex justify-center"></div>
            <img src={Image} alt="Leading Edge" className="w-full h-full" />
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div className="w-full flex items-center justify-center bg-gray-400 py-10 md:px-28 px-10">
        <div>
          <p className=" text-justify font-medium italic text-md text-gray-600 w-[300px]">
            Our product offers unparalleled access to resources and guidance
            that will help you achieve success in your farming.
          </p>
        </div>
      </div>
      {/* Third Section */}
      <div className="md:px-24 px-12 py-24">
        <div className="md:flex justify-between">
          <div className="md:w-[30%] flex flex-col justify-center items-center">
            <h1 className="md:text-3xl text-xl font-medium mb-14">
              Join the KoboWeb ecosystem{" "}
              <span className="text-green-600">today</span>
            </h1>
            <div>
              <img src={joinUsDevice} alt="Leading Edge" className="" />
            </div>
          </div>
          {/* Second COlumn */}
          <div className="md:w-[50%] md:mt-0 mt-14">
            {data.map((item, index) => (
              <div key={index} className="my-2">
                <p className="text-sm text-justify">
                  <span className="font-bold mr-3">{`Step ${index + 1}:`}</span>
                  {item}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
