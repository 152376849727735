import React from "react";
import diffImg1 from "../../assets/images/diffimgone.png";
import diffImg2 from "../../assets/images/diffimgtwo.png";
import diffImg3 from "../../assets/images/diffimgthree.png";

const TheDifference = () => {
  const data = [
    {
      image: diffImg1,
      title: "Knowledge",
      desc: "Regenerative Agriculture (Carbon Farming)",
    },
    {
      image: diffImg2,
      title: "Finance",
      desc: "Financial inclusion and literacy",
    },
    {
      image: diffImg3,
      title: "Market",
      desc: "Exchange of goods and services",
    },
  ];
  function renderContent(item, index) {
    // const { diffImg } = item;
    return (
      <div
        key={index}
        className={`${
          index === 1
            ? "md:mx-8 mx-3 md:my-0 my-5"
            : "md:mx-8 mx-3 md:my-0 my-5"
        }`}
      >
        <img
          src={item.image}
          className="h-[300px] w-full rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-none"
        />
        <div className="p-5 bg-green-600 -mt-5  rounded-bl-lg rounded-br-lg text-white">
          <h1 className="text-lg font-bold mb-2">{item.title}</h1>
          <p className="text-[12.5px] text-justify font-light">{item.desc}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="md:px-24 px-0 my-14">
      <h1 className=" uppercase font-black md:text-2xl text-2xl text-black mt-10 mb-8 ml-8">
        The KoboWeb <br />
        <span className=" font-medium text-green-600">Difference </span>
      </h1>
      <div className="w-full grid grid-cols-1 md:grid-cols-3 ">
        {data.map((item, index) => renderContent(item, index))}
      </div>
    </div>
  );
};

export default TheDifference;
