import React from "react";
import { Footer, Header } from "../components/Layouts";
import { Home } from "../components/Gallery";

const Gallery = () => {
  return (
    <div>
      <Header />
      <Home />
      <Footer />
    </div>
  );
};

export default Gallery;
