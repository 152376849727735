import { PatnersInfo } from "../components/Generic";
import { Footer, Header } from "../components/Layouts";
import { Home, MainContent } from "../components/Patners";

function Patners() {
  return (
    <>
      <div>
        <Header />
        <Home />
        <PatnersInfo />
        <MainContent />
        <Footer />
      </div>
    </>
  );
}

export default Patners;
