import { Home, CompanyDesc, SocialRes, Team } from "../components/About";
import { Footer, Header } from "../components/Layouts";

function About() {
  return (
    <>
      <div>
        <Header />
        <Home />
        <div className="">
          <CompanyDesc />
          {/* <Team /> */}
          <SocialRes />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default About;
