import React from "react";
import { BsArrowDown } from "react-icons/bs";
import Image from "../../assets/images/about-us-img.png";
const About = () => {
  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 md:px-24 px-12 my-14">
        {/* Description */}
        <div className="my-2 py-5 md:pr-10 pr-0 w-full">
          <h1
            style={{}}
            className=" uppercase font-bold md:text-3xl text-2xl text-black"
          >
            welcome <br />
            <span className=" font-light text-green-600">To our world </span>
          </h1>
          <p className="font-light text-black md:text-md text-sm  text-justify mt-8">
            KoboWeb is a cutting-edge agro technology platform aimed at
            providing farmers with markets, finances, and expert advice to help
            them make sound decisions regarding their agricultural activities.
            Our platform offers unparalleled access to resources and guidance
            that empower farmers to achieve success in their endeavors.
          </p>
        </div>
        {/* Image */}
        <div className="my-2 py-5 relative w-full md:pl-10 pl-0">
          {/* Icon */}
          <div className="flex justify-center"></div>
          <img src={Image} alt="ABout Us" className="w-full h-full" />
        </div>
      </div>
    </>
  );
};

export default About;
