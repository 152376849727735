import React from "react";
import Image from "../../assets/images/leading-edge.png";
const LeadingEdge = () => {
  return (
    <div className="md:px-24 px-4 mb-14 mt-20">
      <h1
        style={{}}
        className=" uppercase font-bold md:text-3xl text-2xl text-black"
      >
        cutting edge <br />
        <span className=" font-light text-green-600">technology </span>
      </h1>
      <div className="w-full md:flex justify-center items-center">
        {/* Description */}
        <div className="my-2 py-5 w-full">
          <p
            className="font-light text-black md:text-lg text-sm text-justify md:p-10 p-5 rounded-md"
            style={{ backgroundColor: "rgba(0, 161, 75, 0.1)" }}
          >
            Kobokolet is a tool to register small holder farmers and their farms
            in the KoboWeb ecosystem, enabling them to access knowledge,
            finance, and market.
          </p>
        </div>
        {/* Image */}
        <div className="my-2 py-5 relative w-full">
          {/* Icon */}
          <div className="flex justify-center"></div>
          <img src={Image} alt="Leading Edge" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

export default LeadingEdge;
