import React from "react";
import diffImg1 from "../../assets/images/diffimgone.png";
import diffImg2 from "../../assets/images/diffimgtwo.png";
import diffImg3 from "../../assets/images/diffimgthree.png";

const Home = () => {
  const data = [
    {
      image: diffImg1,
      title: "Market",
      desc: "With our market feature, farmers can easily list their products and connect with potential buyers. Our platform provides farmers with real-time market prices, enabling them to make informed decisions when it comes to selling their products. We also provide farmers with access to value-added services such as packaging, transportation, and logistics, ensuring that their products reach the market in the best possible condition.",
    },
    {
      image: diffImg2,
      title: "Finance",
      desc: "Through this feature, KoboWeb helps farmers to connect with financial institutions like banks and other lenders. By doing so, farmers can secure loans, access credit facilities, and other financial products that are tailored to their needs. The platform makes the process of securing finance easy and seamless, thereby enabling farmers to focus on what they do best - growing their crops and running their farms.",
    },
    {
      image: diffImg3,
      title: "Advisory",
      desc: "Koboweb's Advisory feature is a cornerstone of our cutting-edge agro technology platform, providing farmers with access to expert advice that can help them make sound decisions regarding their agricultural activities. Our team of experienced agricultural professionals is on hand to offer guidance and support across a range of topics, from crop selection and planting strategies to pest control and harvesting techniques.",
    },
  ];
  return (
    <div className="py-24">
      {/* First Section */}
      <div className="md:px-28 px-12 py-12 ">
        <div>
          <h1 className="font-light md:text-5xl text-2xl text-green-600">
            The KoboWeb <br />
            Difference
          </h1>
          <p className="mt-10">
            <span className="font-bold">From Farms to Markets:</span> KoboWeb
            has you covered
          </p>
        </div>
      </div>
      {/* Second Section */}
      <div
        className="md:px-32 px-12"
        style={{ backgroundColor: "rgba(0, 161, 75, 0.1)" }}
      >
        {data.map((item, index) => (
          <div
            // className={`w-full md:flex justify-between ${
            //   index === 1 ? " flex-row" : "flex-row-reverse"
            // }`}
            className="w-full flex py-10"
            key={index}
          >
            {/* First Column (Image) */}
            <div className="h-[300px] w-[300px] md:mr-16">
              <img src={item.image} className="w-full h-full" />
            </div>
            <div
              className="w-1/2"
              //   style={{ backgroundColor: "rgba(0, 161, 75, 0.1)" }}
            >
              <h1 className="font-bold text-xl mb-4">{item.title}</h1>
              <p className="font-light text-justify ">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
      {/* Third Section */}

      <div className="mt-14 w-full flex justify-between items-center">
        <div className="md:block hidden h-[.5px] w-[40%] bg-black"></div>
        <div className="md:w-[50%] md:px-0 px-12">
          <p className="italic text-justify md:pr-24">
            Empower your farming business with KoboWeb - the state-of-the-art
            agro technology platform that connects you to markets, finances, and
            expert advice, enabling you to make informed decisions and grow your
            agricultural activities with confidence
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
